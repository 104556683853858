import React, { Component } from 'react';
import SideMenu from '../admin/SideMenu';
import { appDatabasePrimaryFunctions, base } from '../../base';
import TopMenu from '../admin/TopBar';
import '../../styles/css/AdminMain.css';
import {cleanCSVText, findFirstAndLastName} from '../utils/HelpfulFunction';
import DatePicker from '../utils/DatePicker';

class AdminHome extends Component {
    constructor(props) {
        super(props);
        const todaysDateObject = new Date();
        todaysDateObject.setDate(todaysDateObject.getDate()-30);
        this.state = {
            games: 0,
            users: 0,
            itemsPerPage: 10,
            itemsToShow: 10,
            uniqueGamesUsers: 0,
            loading: false,
            players: 0,
            gameUsers: 0,
            prizesWon: 0,
            lastGame: {},
            uniqueGameUsers: false,
            gamesToShowList: [],
            searchStart: todaysDateObject,
            searchEnd: new Date()
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange (evt) {
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [target.name]: value
        });
    }

    componentDidMount(){
        this.getGames(this.state.searchStart.getTime(), this.state.searchEnd.getTime());
        this.getUsers(this.state.searchStart.getTime(), this.state.searchEnd.getTime());
        // const questions = [
        //         {questionsId:'-MX3ULLPfjJucqc_9Wyq',answers: ["-MX3U9S2-2n7AnNZMQd0"]},
        //         {questionsId:"-MX3UzFYmj9eepaZeWdK", answers:["-MX3UxmI9b3dr1I3BVVE", "-MX3UycnCLKt10PO6jPx"]},
        //         {questionsId:"-MX3VAjIhtag_72wddSi", answers: ["-MX3V6jfEPTd7E8fIF1G", "-MX3V9OomX0aBOi920Uu"]},
        //         {questionsId:"-MX3Un_YkbiebM2rqA6x", answers: ["-MX3Uf_29Phb4xQCkVwj", "-MX3UmtfdkJj31ElRw18"]},
        //         {questionsId:"-MX3UZYROfnIwoP0K3wT", answers: ["-MX3UWYQKxe4w4c7mWhQ"]}
        //     ];
        // for(let i = 0; i<100; i++){
        //     let user = {};
        //     let userAnswer = {};
        //     let email = "leejorgensen22+"+i+"@gmail.com";
        //     let uid = btoa(email);
        //     let timeStamp = new Date().getTime();
        //     for(let questionId in questions){
        //         let questionObject = questions[questionId];
        //         let answerIndex = getRandomInt(questionObject.answers.length)
        //         userAnswer[questionObject.questionsId] = questionObject.answers[answerIndex]
        //     }
        //     userAnswer['timeStamp'] = timeStamp;
        //     user['email'] = email;
        //     user['uid'] = uid;
        //     user['lastSignIn'] = timeStamp;
        //     // console.log(userAnswer)
        //     // console.log(user)
        //     appDatabasePrimaryFunctions.ref('users').child(uid).set(user)
        //     appDatabasePrimaryFunctions.ref('userAnswers').child(uid).set(userAnswer)
        // }
    }

    async getGames(start = 0, end){
        const vm = this;
        let queryString = appDatabasePrimaryFunctions.ref('gamesList').orderByChild('timeStamp').startAt(start);
        let currentGame = null;
        let currentGameObject = await appDatabasePrimaryFunctions.ref('currentGame').once('value').then(function(snapshot){
            let gameTemp = snapshot.val();
            if(gameTemp && gameTemp.timeStamp > start){
                return gameTemp;
            } else {
                return null;
            }
        })
        if(end){
            end = end + 86400000;
            queryString = queryString.endAt(end);
            if(currentGameObject && currentGameObject.timeStamp > end){
                currentGameObject = null;
            }
        }
        currentGame = currentGameObject;
        let currentGamePrizes = 0;
        let currentGameUsers = 0;
        let gamesCount = 0;
        if(currentGame){
            currentGamePrizes = currentGamePrizes + await appDatabasePrimaryFunctions.ref('emailsSent').once('value').then(function(snapshot){
                return snapshot.numChildren()
            })
            currentGameUsers = currentGameUsers +  await appDatabasePrimaryFunctions.ref("userGameHistory").orderByChild(currentGame.id).equalTo(currentGame.id).once('value').then(function (snapshot){
                return snapshot.numChildren()
            })
            // const answersCount = await appDatabasePrimaryFunctions.ref("userAnswers").once('value').then(function(snapshot){
            //     return snapshot.numChildren()
            // })
            gamesCount++;
            currentGame.prizesWon = currentGamePrizes;
            currentGame.users = currentGameUsers;
            currentGame.currentGame = true;
        }
        queryString.once('value', function (snapshot) {
            let prizesCount = currentGamePrizes;
            let gameUsersCount = currentGameUsers;
            let gamesArray = [];
            let index = 0;
            snapshot.forEach(function (data) {
                index++;
                prizesCount += data.val().prizesWon || 0;
                gameUsersCount += data.val().users || 0;
                if(snapshot.numChildren() - vm.state.itemsPerPage < index){
                    gamesArray.unshift(data.val())
                }
            })
            if(currentGame){
                gamesArray.unshift(currentGame)
            }
            vm.setState({
                loading: false,
                games: gamesCount + snapshot.numChildren(),
                prizesWon: prizesCount,
                gameUsers: gameUsersCount,
                gamesToShowList: gamesArray
            })
        });
    }

    async getUsers(start=0, end, returnUsers=false){
        const vm = this;
        let queryString = appDatabasePrimaryFunctions.ref('users').orderByChild('signUpTime');
        if(start){
            queryString = queryString.startAt(start);
        }
        if(end){
            queryString = queryString.endAt(end + 86400000);
        }
        if(returnUsers){
            return await queryString.once('value').then(function(snapshot) {
                if(snapshot.exists()){
                    return snapshot.val();
                } else {
                    return {};
                }
            })
        } else {
            queryString.once('value', function(snapshot){
                vm.setState({
                    loading: false,
                    users: snapshot.numChildren()
                })
            });
        }
    }

    async getUserData(){
        this.setState({
            loading: true
        })
        let searchStart = this.state.searchStart || 0;
        let searchEnd = this.state.searchEnd;
        if(typeof searchStart === "object"){
            searchStart = searchStart.getTime()
        }
        if(searchEnd && typeof searchEnd === "object"){
            searchEnd = searchEnd.getTime()
        }
        let csv = process.env.REACT_APP_CLEAN_CLIENT_NAME || process.env.REACT_APP_FIREBASE_PROJECT_ID;
        const date_got = new Date((new Date().getTime() - new Date().getTimezoneOffset()*60*1000)).toISOString().split("T")[0];
        csv+=",Prediction\n,\n"
        csv+="Date Data Downloaded,"+date_got+"\n";
        csv+="From,"+(searchStart?this.convertSecondsTimeStampToHumanTime(searchStart/1000):"")+"\n";
        csv+="To,"+(searchEnd?this.convertSecondsTimeStampToHumanTime(searchEnd/1000):"")+"\n,\n";
        csv += 'Email,First Name,Last Name,Phone Number,Zip Code,Address,Birthday,Opt-In,Signed Up\n';
        const vm = this;
        let timeFrameUsers = await this.getUsers(searchStart, searchEnd, true);
        for(let timeFrameUserIndex in timeFrameUsers){
            let user = timeFrameUsers[timeFrameUserIndex];
            let {firstName, secondName} = findFirstAndLastName(user.name);
            csv += (user.email || user.uid || "") + ",";
            csv += (cleanCSVText(firstName) || "") + ",";
            csv += (cleanCSVText(secondName) || "") + ",";
            csv += (user.phoneNumber || "") + ",";
            csv += (cleanCSVText(user.zipCode) || "") + ",";
            csv += (cleanCSVText(user.address) || "") + ",";
            csv += (user.birthday || "") + ",";
            csv += (user.optIn || "") + ",";
            csv += (user.signUpTime?this.convertSecondsTimeStampToHumanTime(user.signUpTime/1000):"")
            csv += "\n";
        }
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        hiddenElement.target = '_blank';
        let cleanString = (searchStart?"_"+this.convertSecondsTimeStampToHumanTime(searchStart/1000):"")+(searchEnd?"_"+this.convertSecondsTimeStampToHumanTime(searchEnd/1000):"");
        cleanString = "prediction_accounts_made" + cleanString.replace(/[|&;$%@"<>()+,]/g, "").toLowerCase()+".csv"
        hiddenElement.download = cleanString;
        hiddenElement.click();
        vm.setState({
            loading:false
        })
    }

    async downloadIntenseGameData(game){
        this.setState({
            loading: true
        })
        let csv = process.env.REACT_APP_FAN_LINK + ',' + 'Prediction\n""\n';
        let date_downloaded = new Date((new Date().getTime() - new Date().getTimezoneOffset()*60*1000)).toISOString().split("T")[0];
        const gameAnswers = await appDatabasePrimaryFunctions.ref(`userAnswersHistory/${game.id}`).once('value').then(function(snapshot){
            if(snapshot.exists()){
                return snapshot.val();
            } else {
                return []
            }
        });
        let totalPrizes = 0;
        for(const userIndex in gameAnswers){
            const userInfo = gameAnswers[userIndex];
            if(userInfo.rewardSent){
                totalPrizes++;
            }
        }
        csv+= 'Date Data Downloaded,'+date_downloaded+'\n';
        csv+= 'Game Name,'+game.gameName+'\n';
        csv+= 'Start Time,'+(game.scheduleInfo?this.convertSecondsTimeStampToHumanTime(game.scheduleInfo.performAt):"")+'\n';
        csv+= 'End Time,'+(game.scheduleInfo?this.convertSecondsTimeStampToHumanTime(game.scheduleInfo.endAt):"")+'\n';
        csv+= 'Prizes Won,'+totalPrizes.toString()+'\n""\n';
        csv+= 'Email,First Name,Last Name,Phone Number,Zip Code,Address,Birthday,Opt-In,Number Correct,Won?,Reward,Code,Link,Answer Time\n';

        const usersData = await appDatabasePrimaryFunctions.ref('users').once('value').then(function(usersSnapshot){
            return usersSnapshot.val();
        })

        for(const userIndex in gameAnswers){
            const userInfo = gameAnswers[userIndex];
            const rewardSent = userInfo.rewardSent ? "YES": "NO";
            const moreUserData = usersData[userInfo.uid];
            let {firstName, secondName} = findFirstAndLastName(moreUserData.name);
            csv+= (moreUserData.email || moreUserData.uid || "")
            +','+(cleanCSVText(firstName) || "")
            +','+(cleanCSVText(secondName) || "")
            +','+(moreUserData.phoneNumber || "")
            +','+(cleanCSVText(moreUserData.zipCode) || "")
            +","+(cleanCSVText(moreUserData.address) || "")
            +','+(moreUserData.birthday||"")
            +','+(moreUserData.optIn||"")
            +','+(userInfo.correctAnswers||"")
            +','+(rewardSent||"")
            +','+(cleanCSVText(userInfo.rewardSent)||"")
            +','+(cleanCSVText(userInfo.code)||"")
            +','+(cleanCSVText(userInfo.link)||"")
            +','+this.convertSecondsTimeStampToHumanTime(parseInt(userInfo.timeStamp/1000))+'\n'
        }
        let hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        hiddenElement.target = '_blank';
        let cleanString = game.gameName.replace(/[|&;$%@"<>()+,. ]/g, "").toLowerCase();
        hiddenElement.download = "prediction_game_data_"+cleanString+".csv";
        hiddenElement.click();
        this.setState({
            loading: false
        })
    }

    searchData(){
        let searchStart = this.state.searchStart;
        let searchEnd = this.state.searchEnd;
        if(searchStart){
            searchStart = searchStart.getTime();
        }
        if(searchEnd){
            searchEnd = searchEnd.getTime();
        }
        this.setState({
            loading: true,
            itemsPerPage: this.state.itemsPerPage
        }, ()=>{

        })
        this.getGames(searchStart,searchEnd)
        this.getUsers(searchStart,searchEnd)
    }

    convertSecondsTimeStampToHumanTime(dateInSeconds){
        if(dateInSeconds){
            var tzoffset = (new Date()).getTimezoneOffset() * 60;
            var afterTimezoneChangeSeconds = dateInSeconds - tzoffset
            return this.toDateTime(afterTimezoneChangeSeconds).toDateString() + " " + this.toStandardTime(new Date(this.toDateTime(afterTimezoneChangeSeconds).getTime()).toString().split(" ")[4])
        } else {
            return ""
        }
    }

    toDateTime(secs) {
        var t = new Date(1970, 0, 1); // Epoch
        t.setSeconds(secs);
        return t;
    }

    toStandardTime(value) {
        if (value !== null && value !== undefined){ //If value is passed in
            if(value.indexOf('AM') > -1 || value.indexOf('PM') > -1){ //If time is already in standard time then don't format.
                return value;
            }
            else {
                if(value.length == 8){ //If value is the expected length for military time then process to standard time.
                    var hour = value.substring ( 0,2 ); //Extract hour
                    var minutes = value.substring ( 3,5 ); //Extract minutes
                    var identifier = 'AM'; //Initialize AM PM identifier

                    if(hour == 12){ //If hour is 12 then should set AM PM identifier to PM
                        identifier = 'PM';
                    }
                    if(hour == 0){ //If hour is 0 then set to 12 for standard time 12 AM
                        hour=12;
                    }
                    if(hour > 12){ //If hour is greater than 12 then convert to standard 12 hour format and set the AM PM identifier to PM
                        hour = hour - 12;
                        identifier='PM';
                    }
                    return hour + ':' + minutes + ' ' + identifier; //Return the constructed standard time
                }
                else { //If value is not the expected length than just return the value as is
                    return value;
                }
            }
        }
    }

    onNextPage() {
        const vm = this;
        const newAmountToShow = this.state.itemsToShow + this.state.itemsPerPage;
        let searchStart = this.state.searchStart;
        let searchEnd = this.state.searchEnd;
        if(searchStart){
            searchStart = searchStart.getTime();
        }
        if(searchEnd){
            searchEnd = searchEnd.getTime();
        }
        let queryString = appDatabasePrimaryFunctions.ref('gamesList').orderByChild('timeStamp').limitToLast(newAmountToShow).startAt(searchStart);
        if (searchEnd) {
            queryString = queryString.endAt(searchEnd + 86400000)
        }
        queryString.once('value', function (snapshot) {
            let gamesArray = [];
            snapshot.forEach(function (data) {
                gamesArray.unshift(data.val())
            })
            vm.setState({
                itemsToShow: newAmountToShow,
                gamesToShowList: gamesArray
            });
        })
    }

    render() {
        const totalUsers = this.state.users || 0;
        const gameUsers = this.state.gameUsers || 0;
        const prizesWon = this.state.prizesWon || 0;
        const fan_side_link = process.env.REACT_APP_FAN_LINK || "";
        const scoreboard_horizontal_link = process.env.REACT_APP_SCOREBOARD_HORIZONTAL_LINK || "";
        const scoreboard_vertical_link = process.env.REACT_APP_SCOREBOARD_VERTICAL_LINK || "";

        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
                <div className="row" style={{width:'100%'}}>
                    <div className="col-md-11">
                        <p className="admin-header-text" style={{marginTop:'10px'}}>Game link: <span style={{fontSize:'20px'}}>{fan_side_link}</span><br/>
                            Scoreboard Top Image Link: <span style={{fontSize:'20px'}}>{scoreboard_vertical_link}</span><br/>
                            Scoreboard Left Image link: <span style={{fontSize:'20px'}}>{scoreboard_horizontal_link}</span>
                        </p>
                    </div>
                    <div className="col-md-1">
                        <p style={{color: "black"}}>
                            v{process.env.REACT_APP_VERSION}
                        </p>
                    </div>
                </div>
                <form className="form-horizontal">
                    <div className="row" style={{marginLeft: 20}}>
                        <div className="form-group" style={{marginRight: 10}}>
                            <DatePicker isClearable selected={this.state.searchStart} onChange={date => this.setState({searchStart: date})}/>
                        </div>

                        <div className="form-group" style={{marginRight: 10}}>
                            <DatePicker isClearable selected={this.state.searchEnd} onChange={date => this.setState({searchEnd: date})}/>
                        </div>
                        <div>
                            <button className="btn btn-primary btn-admin" type="button" onClick={()=>this.searchData()}>Search</button>
                        </div>
                    </div>
                </form>
                <div className="admin-grid-container four-columns">
                    <div className="card card-styles text-xs-center" style={{backgroundColor:"black"}}>
                        <div className="card-body">
                            <blockquote className="card-bodyquote" style={{margin:0}}>
                                <div className="row">
                                    <div className="col-md-8">
                                        <p style={{color:"white", fontSize:50, margin:0}}>{totalUsers}</p>
                                        <span style={{color:"#f8f8ff", fontSize:20}}>Accounts Created</span>
                                    </div>
                                    <div className="col-md-4" style={{fontSize:40, alignSelf:"center"}}>
                                        <i className="fa fa-arrow-circle-o-down" aria-hidden="true" style={{color:"white", cursor: "pointer"}} onClick={()=> this.getUserData()}/>
                                    </div>
                                </div>
                            </blockquote>
                        </div>
                    </div>
                    <div className="card card-styles text-xs-center" style={{backgroundColor:"black"}}>
                        <div className="card-body">
                            <blockquote className="card-bodyquote" style={{margin:0}}>
                                <div className="row">
                                    <div className="col-md-8">
                                        <p style={{color:"white", fontSize:50, margin:0}}>{gameUsers}</p>
                                        <span style={{color:"#f8f8ff", fontSize:20}}>Game Users</span>
                                    </div>
                                </div>
                            </blockquote>
                        </div>
                    </div>
                    <div className="card card-styles text-xs-center" style={{backgroundColor:"black"}}>
                        <div className="card-body">
                            <blockquote className="card-bodyquote" style={{margin:0}}>
                                <div className="row">
                                    <div className="col-md-8">
                                        <p style={{color:"white", fontSize:50, margin:0}}>{prizesWon}</p>
                                        <span style={{color:"#f8f8ff", fontSize:20}}>Prizes Won</span>
                                    </div>
                                </div>
                            </blockquote>
                        </div>
                    </div>
                </div>
                <div style={{margin:20}}>
                    {this.state.gamesToShowList && this.state.gamesToShowList.length > 0 &&
                        <>
                            {
                                this.state.gamesToShowList.map(function(item,index){
                                    return (
                                        <div key={index} className="card card-styles text-xs-center" style={{marginBottom:10}}>
                                            <div className="card-body" style={{boxShadow: "rgba(0, 0, 0, 0.2) 0px 4px 8px 0px"}}>
                                                <div className="row" style={{alignItems: 'center'}}>
                                                    <div className="col-md-2">
                                                        <span style={{color:'black', fontSize: 14}}>{this.convertSecondsTimeStampToHumanTime(item.timeStamp/1000)}</span>
                                                    </div>
                                                    <div className="col-md-3" style={{fontSize: 30}}>
                                                        {item.gameName}
                                                    </div>
                                                    <div className="col-md-3" style={{textAlign:'right'}}>
                                                        <span style={{fontSize:25}}>{item.users || 0}</span>
                                                        <span style={{color:'black', fontSize: 15, marginRight: 15}}>Played</span>
                                                        <span style={{fontSize:25}}>{item.prizesWon || 0}</span>
                                                        <span style={{color:'black', fontSize: 15}}>Prizes Won</span>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="row" style={{fontSize: 20, justifyContent: 'center'}}>
                                                            {item.prizesWon !== null && !item.currentGame &&
                                                                <button onClick={() => this.downloadIntenseGameData(item)} className="export-button-styles btn btn-primary btn-lg download-button mobile-hide">
                                                                    <span className="fa fa-arrow-circle-down"/> Download Game Data
                                                                </button>
                                                            }
                                                            {item.currentGame &&
                                                                <button onClick={() => window.location.href='/setupgame'} className="export-button-styles btn btn-primary btn-lg download-button mobile-hide">
                                                                    Go To Current Game
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }, this)
                            }
                            {this.state.games > this.state.gamesToShowList.length &&
                                <button className="btn btn-primary" onClick={()=>this.onNextPage()}>
                                    More
                                </button>
                            }
                        </>
                    }
                </div>
            </div>
         </div>
        );
    }
}

export default AdminHome
